<template>
<div>
    <!-- <linkbutton toplabel="Update Parent" @click="UpdateParent()"/> -->
    <pencilbutton @click="UpdateParent()"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="myform">
                <label class="titlelabel">Update Wallet Settings</label>
                <formentry   label="First Name"  v-model="parent.FirstName" />
                <formentry    label="Last Name"  v-model="parent.LastName" />
                
                <formentry  inputtype="tel"  label="Phone Number" @blur="leavePhone"  v-model="parent.PhoneNumber1" />
                <formentry  inputtype="email"  label="Email Address"  v-model="parent.EmailAddress" />
                <div class="vertFlex ">
                    <label for="" class="mediumtext">How do you want to receive updates?</label>
                    <label for="" class="mediumtext">(choose at least one)</label>
                
                    <div class="horizFlex topMargin centered">
                        <input class="entryCheckbox" type="checkbox" v-model="parent.optInForText" >
                        <label for="" :class="checkClass(parent.optInForText)">Via mobile phone text message </label>
                    </div>
                    <label for="" class="mediumtext">(Standard messaging and data rates may apply.)</label>
                    <div class="horizFlex centered">
                        <input class="entryCheckbox" type="checkbox" v-model="parent.optInForEmail" >
                        <label for="" :class="checkClass(parent.optInForEmail)">Via email</label>
                    </div>
                </div>
    
                <smallbutton  @click="saveNewParent()" toplabel="Save" />
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
                <div>
                    <label class="responsemessage">{{message}}</label>
               </div>
          </form>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'
import pencilbutton from '../controls/pencilbutton.vue'
export default {
    name:'updateparent',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton,
        pencilbutton
    },
    props:{
        personId:{
            type:Number,
            required:true
        },
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            message:'',
            parent:{}
        }
    },
    methods:{
        async UpdateParent(){
            this.message = ''
            let req = {
                person:{
                    ID:this.personId
                }
            }

            var response = await this.callAPI(req,'getperson')    
            if(response.Successful){
                console.log(response)
                this.parent=response.Person;
                this.parent.PhoneNumber1=this.$options.filters.formatPhone(this.parent.PhoneNumber1);
                this.$refs.mymodal.openModal()
            }else{
                this.message = response.Message
            }

        },
        
        checkClass(selected){
            if(selected){
                return "entryCheckLabelbold"
            }else{
                return "entryCheckLabel"
            }
        },
        async saveNewParent(){
            if(this.$refs.myform.checkValidity()==false){return}
            if(!this.parent.optInForText && !this.parent.optInForEmail){
                this.message="Please select either text or email updates"
                return
            }
            if(this.parent.optInForText && this.parent.PhoneNumber1==''){
                this.message="Please enter a phone number for text updates"
                return
            }

            let req = {
                person:this.parent,
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'updateperson')
            if(response.Successful){
                this.$refs.mymodal.closeModal()
                console.log(response)
                this.$emit('refresh',response.walletId)
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        leavePhone(){
            this.parent.PhoneNumber1=this.$options.filters.formatPhone(this.parent.PhoneNumber1);
        },
    }
}
</script>

<style>
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
</style>





























